import React from "react";
import { Layout, Typography, Row, Col } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
import HomeHeader from "../components/HomeHeader";
import HomePageFooter from "../components/HomePageFooter";
import "../assets/styles/privacyPolicy.css";

const { Title, Paragraph, Text } = Typography;

const PrivacyPolicy = () => {
  return (
    <>
      <Layout className="layout-dashboard">
        <HomeHeader />
        <Content style={{ padding: "20px" }}>
          <div className="section">
            <Title level={2} className="title">
              Privacy Policy
            </Title>
            <Paragraph className="description">
              <Text strong>Last updated: November 27, 2024</Text>
            </Paragraph>
            <Paragraph className="description">
              This Privacy Policy describes Our policies and procedures on the
              collection, use, and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
            </Paragraph>
            <Paragraph className="description">
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy. 
            </Paragraph>

            {/* Interpretation and Definitions Section */}
            <Title level={3} className="subtitle">
              Interpretation and Definitions
            </Title>
            <Title level={4} className="sub-subtitle">
              Interpretation
            </Title>
            <Paragraph className="description">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </Paragraph>

            <Title level={4} className="sub-subtitle">
              Definitions
            </Title>
            <Paragraph className="description">
              For the purposes of this Privacy Policy:
            </Paragraph>
            <ul className="description-list">
              <li>
                <Text strong>Account</Text> means a unique account created for
                You to access our Service or parts of our Service.
              </li>
              <li>
                <Text strong>Affiliate</Text> means an entity that controls, is
                controlled by or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest, or other securities entitled to vote for election of
                directors or other managing authority.
              </li>
              <li>
                <Text strong>Application</Text> refers to Shankh App, the
                software program provided by the Company.
              </li>
              <li>
                <Text strong>Company</Text> (referred to as either "the
                Company", "We", "Us" or "Our" in this Agreement) refers to
                InfinityPool, Pune, IN.
              </li>
              <li>
                <Text strong>Cookies</Text> are small files that are placed on
                Your computer, mobile device, or any other device by a website,
                containing the details of Your browsing history on that website
                among its many uses.
              </li>
              <li>
                <Text strong>Country</Text> refers to: Maharashtra, India.
              </li>
              <li>
                <Text strong>Device</Text> means any device that can access the
                Service such as a computer, a cellphone, or a digital tablet.
              </li>
              <li>
                <Text strong>Personal Data</Text> is any information that
                relates to an identified or identifiable individual.
              </li>
              <li>
                <Text strong>Service</Text> refers to the Application or the
                Website or both.
              </li>
              <li>
                <Text strong>Service Provider</Text> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service, or to assist the Company in analyzing how the Service
                is used.
              </li>
              <li>
                <Text strong>Third-party Social Media Service</Text> refers to
                any website or any social network website through which a User
                can log in or create an account to use the Service.
              </li>
              <li>
                <Text strong>Usage Data</Text> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </li>
              <li>
                <Text strong>Website</Text> refers to Shankh.tech, accessible
                from{" "}
                <a
                  href="https://shankh.tech/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://shankh.tech/
                </a>
                .
              </li>
              <li>
                <Text strong>You</Text> means the individual accessing or using
                the Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </li>
            </ul>
          </div>
          {/* Collecting and Using Your Personal Data Section */}
          <div className="section">
            <Title level={3} className="subtitle">
              Collecting and Using Your Personal Data
            </Title>

            <Title level={4} className="sub-subtitle">
              Types of Data Collected
            </Title>
            <Title level={4} className="sub-subtitle">
              Personal Data
            </Title>
            <Paragraph className="description">
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
              <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
              </ul>
            </Paragraph>

            <Title level={4} className="sub-subtitle">
              Usage Data
            </Title>
            <Paragraph className="description">
              Usage Data is collected automatically when using the Service.
              <br />
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers,
              and other diagnostic data.
              <br />
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers, and other diagnostic data.
              <br />
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </Paragraph>

            <Title level={4} className="sub-subtitle">
              Information from Third-Party Social Media Services
            </Title>
            <Paragraph className="description">
              The Company allows You to create an account and log in to use the
              Service through the following Third-party Social Media Services:
              <ul>
                <li>Google</li>
                <li>Outlook</li>
                <li>Zoho</li>
                <li>Yahoo</li>
              </ul>
              If You decide to register through or otherwise grant us access to
              a Third-Party Social Media Service, We may collect Personal Data
              that is already associated with Your Third-Party Social Media
              Service's account, such as Your name, Your email address,
              <br />
              You may also have the option of sharing additional information
              with the Company through Your Third-Party Social Media Service's
              account. If You choose to provide such information and Personal
              Data, during registration or otherwise, You are giving the Company
              permission to use, share, and store it in a manner consistent with
              this Privacy Policy.
            </Paragraph>
            <Title level={4} className="sub-subtitle">
              Tracking Technologies and Cookies
            </Title>
            <Paragraph className="description">
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
              <ul>
                <li>
                  <strong>Cookies or Browser Cookies:</strong> A cookie is a
                  small file placed on Your Device. You can instruct Your
                  browser to refuse all Cookies or to indicate when a Cookie is
                  being sent. However, if You do not accept Cookies, You may not
                  be able to use some parts of our Service. Unless you have
                  adjusted Your browser setting so that it will refuse Cookies,
                  our Service may use Cookies.
                </li>
                <li>
                  <strong>Web Beacons:</strong> Certain sections of our Service
                  and our emails may contain small electronic files known as web
                  beacons (also referred to as clear gifs, pixel tags, and
                  single-pixel gifs) that permit the Company, for example, to
                  count users who have visited those pages or opened an email
                  and for other related website statistics (for example,
                  recording the popularity of a certain section and verifying
                  system and server integrity).
                </li>
              </ul>
              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              Your web browser. You can learn more about cookies on TermsFeed
              website article.
              <br />
              We use both Session and Persistent Cookies for the purposes set
              out below:
              <ul>
                <li>
                  <strong>Necessary / Essential Cookies:</strong> These Cookies
                  are essential to provide You with services available through
                  the Website and to enable You to use some of its features.
                  They help to authenticate users and prevent fraudulent use of
                  user accounts. Without these Cookies, the services that You
                  have asked for cannot be provided, and We only use these
                  Cookies to provide You with those services.
                </li>
                <li>
                  <strong>Cookies Policy / Notice Acceptance Cookies:</strong>{" "}
                  These Cookies identify if users have accepted the use of
                  cookies on the Website.
                </li>
                <li>
                  <strong>Functionality Cookies:</strong> These Cookies allow us
                  to remember choices You make when You use the Website, such as
                  remembering your login details or language preference. The
                  purpose of these Cookies is to provide You with a more
                  personal experience and to avoid You having to re-enter your
                  preferences every time You use the Website.
                </li>
              </ul>
            </Paragraph>
            <Title level={3} className="subtitle">
              Use of Your Personal Data
            </Title>
            <Paragraph className="description">
              The Company may use Personal Data for the following purposes:
              <ul>
                <li>
                  <b>To provide and maintain our Service:</b> Including to
                  monitor the usage of our Service.
                </li>
                <li>
                  <b>To manage Your Account:</b> To manage Your registration as
                  a user of the Service. The Personal Data You provide can give
                  You access to different functionalities of the Service that
                  are available to You as a registered user.
                </li>
                <li>
                  <b>For the performance of a contract:</b> The development,
                  compliance, and undertaking of the purchase contract for the
                  products, items, or services You have purchased or of any
                  other contract with Us through the Service.
                </li>
                <li>
                  <b>To contact You:</b> To contact You by email, telephone
                  calls, SMS, or other equivalent forms of electronic
                  communication, such as a mobile application's push
                  notifications regarding updates or informative communications
                  related to the functionalities, products, or contracted
                  services, including the security updates, when necessary or
                  reasonable for their implementation.
                </li>
                <li>
                  <b>
                    To provide You with news, special offers, and general
                    information:
                  </b>{" "}
                  About other goods, services, and events which we offer that
                  are similar to those that you have already purchased or
                  enquired about unless You have opted not to receive such
                  information.
                </li>
                <li>
                  <b>To manage Your requests:</b> To attend and manage Your
                  requests to Us.
                </li>
                <li>
                  <b>For business transfers:</b> We may use Your information to
                  evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of Our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  Personal Data held by Us about our Service users is among the
                  assets transferred.
                </li>
                <li>
                  <b>For other purposes:</b> We may use Your information for
                  other purposes, such as data analysis, identifying usage
                  trends, determining the effectiveness of our promotional
                  campaigns, and to evaluate and improve our Service, products,
                  services, marketing, and your experience.
                </li>
              </ul>
            </Paragraph>
            <Paragraph className="description">
              We may share Your personal information in the following
              situations:
              <ul>
                <li>
                  <b>With Service Providers:</b> We may share Your personal
                  information with Service Providers to monitor and analyze the
                  use of our Service, or to contact You.
                </li>
                <li>
                  <b>For business transfers:</b> We may share or transfer Your
                  personal information in connection with, or during
                  negotiations of, any merger, sale of Company assets,
                  financing, or acquisition of all or a portion of Our business
                  to another company.
                </li>
                <li>
                  <b>With Affiliates:</b> We may share Your information with Our
                  affiliates, in which case we will require those affiliates to
                  honor this Privacy Policy. Affiliates include Our parent
                  company and any other subsidiaries, joint venture partners, or
                  other companies that We control or that are under common
                  control with Us.
                </li>
                <li>
                  <b>With business partners:</b> We may share Your information
                  with Our business partners to offer You certain products,
                  services, or promotions.
                </li>
                <li>
                  <b>With other users:</b> When You share personal information
                  or otherwise interact in the public areas with other users,
                  such information may be viewed by all users and may be
                  publicly distributed outside. If You interact with other users
                  or register through a Third-Party Social Media Service, Your
                  contacts on the Third-Party Social Media Service may see Your
                  name, profile, pictures, and description of Your activity.
                  Similarly, other users will be able to view descriptions of
                  Your activity, communicate with You, and view Your profile.
                </li>
                <li>
                  <b>With Your consent:</b> We may disclose Your personal
                  information for any other purpose with Your consent.
                </li>
              </ul>
            </Paragraph>
          </div>
          <div className="section">
            <Title level={3} className="subtitle">
              Retention of Your Personal Data
            </Title>
            <Paragraph className="description">
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </Paragraph>
            <Paragraph className="description">
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </Paragraph>
          </div>

          {/* Transfer of Your Personal Data Section */}
          <div className="section">
            <Title level={3} className="subtitle">
              Transfer of Your Personal Data
            </Title>
            <Paragraph className="description">
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </Paragraph>
            <Paragraph className="description">
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </Paragraph>
            <Paragraph className="description">
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </Paragraph>
          </div>

          {/* Delete Your Personal Data Section */}
          <div className="section">
            <Title level={3} className="subtitle">
              Delete Your Personal Data
            </Title>
            <Paragraph className="description">
              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
            </Paragraph>
            <Paragraph className="description">
              Our Service may give You the ability to delete certain information
              about You from within the Service.
            </Paragraph>
            <Paragraph className="description">
              You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.
            </Paragraph>
            <Paragraph className="description">
              Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </Paragraph>
          </div>
          {/* Disclosure of Your Personal Data Section */}
          <div className="section">
            <Title level={3} className="subtitle">
              Disclosure of Your Personal Data
            </Title>

            {/* Business Transactions */}
            <Title level={4} className="subtitle">
              Business Transactions
            </Title>
            <Paragraph className="description">
              If the Company is involved in a merger, acquisition, or asset
              sale, Your Personal Data may be transferred. We will provide
              notice before Your Personal Data is transferred and becomes
              subject to a different Privacy Policy.
            </Paragraph>

            {/* Law Enforcement */}
            <Title level={4} className="subtitle">
              Law Enforcement
            </Title>
            <Paragraph className="description">
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g., a court or
              a government agency).
            </Paragraph>

            {/* Other Legal Requirements */}
            <Title level={4} className="subtitle">
              Other Legal Requirements
            </Title>
            <Paragraph className="description">
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </Paragraph>
            <Paragraph className="description">
              <ul>
                <li>Comply with a legal obligation</li>
                <li>
                  Protect and defend the rights or property of the Company
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li>
                  Protect the personal safety of Users of the Service or the
                  public
                </li>
                <li>Protect against legal liability</li>
              </ul>
            </Paragraph>
          </div>
          {/* Security of Your Personal Data Section */}
          <div className="section">
            <Title level={3} className="subtitle">
              Security of Your Personal Data
            </Title>
            <Paragraph className="description">
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </Paragraph>
          </div>

          {/* Links to Other Websites Section */}
          <div className="section">
            <Title level={3} className="subtitle">
              Links to Other Websites
            </Title>
            <Paragraph className="description">
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </Paragraph>
            <Paragraph className="description">
              We have no control over and assume no responsibility for the
              content, privacy policies, or practices of any third-party sites
              or services.
            </Paragraph>
          </div>

          {/* Changes to this Privacy Policy Section */}
          <div className="section">
            <Title level={3} className="subtitle">
              Changes to this Privacy Policy
            </Title>
            <Paragraph className="description">
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </Paragraph>
            <Paragraph className="description">
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
            </Paragraph>
            <Paragraph className="description">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </Paragraph>
          </div>

          {/* Contact Us Section */}
          <div className="section">
            <Title level={3} className="subtitle">
              Contact Us
            </Title>
            <Paragraph className="description">
              If you have any questions about this Privacy Policy, you can
              contact us:
              <ul>
                <li>
                  By email:{" "}
                  <a href="mailto:contact@shankh.tech">contact@shankh.tech</a>
                </li>
                <li>
                  By visiting this page on our website:{" "}
                  <a
                    href="https://shankh.tech/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://shankh.tech/contact-us
                  </a>
                </li>
              </ul>
            </Paragraph>
          </div>
        </Content>
      </Layout>
      <HomePageFooter />
    </>
  );
};

export default PrivacyPolicy;
