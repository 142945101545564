import { Switch, Route, Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import "./assets/styles/responsive.css";
import "./assets/styles/complianceReport.css"
import "./assets/styles/main.css";
import HomePage from "./pages/HomePage";
import React, { useState } from 'react';
import Aboutus from "./pages/Aboutus";
import Products from "./pages/Products";
import Media from "./pages/Media";
import Career from "./pages/Career";
import Contactus from "./pages/Contactus";
import PrivacyPolicy from "./pages/PrivacyPolicy";




function App() {
  return (
    <div className="App">
      <Switch>
        <Route path='/homepage' exact component={HomePage}/>
        <Route path="/" exact component={HomePage}/>
        <Route path="/about-us" exact component={Aboutus}/>
        <Route path="/products" exact component={Products}/>
        <Route path="/media" exact component={Media}/>
        <Route path="/career" exact component={Career} />
        <Route path="/contact-us" exact component={Contactus}/>
        <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
      </Switch>
    </div>
  );
}

export default App;
