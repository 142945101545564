import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
} from "antd";
import signinbg from "../assets/images/img-signin.jpg";
import axios from "axios";
import Cookies from "js-cookie";
import "../assets/styles/signin.css";
import logo from "../assets/images/shankh-logo-hz.svg";
import { jwtDecode } from "jwt-decode";
import {
  APP_URL,
  HOST_URL,
  MAIN_DOMAIN,
  OFFICER_URL,
  isLocalhost,
} from "../helper/constant";

function onChange(checked) {
  console.log(`switch to ${checked}`);
}
const { Title } = Typography;
const { Header, Footer, Content } = Layout;

class SignIn extends Component {
  render() {
    const onFinish = async (values) => {
      Cookies.remove("shankhjwt", {
        path: "/",
        domain: MAIN_DOMAIN, // Ensure domain is specified
      });
      try {
        const response = await axios.post(`${HOST_URL}token/`, {
          username: values.email,
          password: values.password,
        });
        const { access } = response.data;

        // Decode the JWT to get is_complianceOfficer and is_advisorOfficer
        const decodedToken = jwtDecode(access);
        const { is_complianceOfficer, is_advisorOfficer } = decodedToken;

        this.props.onClose();

        // Set JWT in an HTTP-only cookie
        Cookies.set("shankhjwt", access, {
          expires: 10,
          path: "/",
          domain: MAIN_DOMAIN,
          sameSite: isLocalhost ? "Lax" : "None", // "None" for cross-origin, "Lax" for local
          secure: !isLocalhost, // Use HTTPS in production
        });

        // Redirect based on user role
        if (is_complianceOfficer) {
          window.location.href = `${OFFICER_URL}dashboard`;
        } else if (is_advisorOfficer) {
          window.location.href = `${OFFICER_URL}advisory-dashboard`;
        } else {
          window.location.href = `${APP_URL}TabNavigation/HomeScreen`;
        }
      } catch (error) {
        console.error("Login failed:", error);
        alert("Login failed. Please check your credentials and try again.");
      }
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    return (
      <>
        <Layout className="layout-default-layout-signin">
          <div className="logo-signin">
            <img src={logo} alt="Shankh" />
          </div>
          <Content className="signin">
            <Title className="mb-10">Log In</Title>
            <Title className="font-regular text-muted" level={5}>
              Enter your email and password to sign in
            </Title>
            <Form
              form={this.props.form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="username"
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                className="username"
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item className="forgot-password-text">
                <span>Forgot Password</span>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "48%" }}
                >
                  LOG IN
                </Button>
                <Button
                  type="primary"
                  href="/contact-us"
                  className="create-acc-btn custom-button"
                >
                  Create Account
                </Button>
              </Form.Item>
            </Form>
          </Content>
        </Layout>
      </>
    );
  }
}
export default withRouter(SignIn);
